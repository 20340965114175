import React from "react"
import { Navigation, Blog, Footer, Hero } from "../../components/Marketing"
import { Seo } from "../../components/Seo/Seo"

export default () => {
  const breadcrumbs = [{ label: "Répertoire de haïkus", url: "/repertoire/" }]

  return (
    <>
      <Seo
        title={`Répertoire des haïkus de la communauté`}
        description={`Que vous recherchiez des haïkus sur le thème de l'amour ou le printemps, `}
        breadcrumbs={breadcrumbs}
        index={false}
      />
      <Navigation />
      <Hero
        theme={{ as: "h2", value: <>Répertoire de haïkus de la communauté</> }}
        title={{
          as: "h1",
          value: (
            <>
              Le répertoire des <span className="text-green-600">haïkus</span>{" "}
              par thématique ou par auteur
            </>
          ),
        }}
        description={{
          value: (
            <>
              Vous cherchez à découvrir les créations des membres de la
              communauté, ou vous cherchez simplement un espace pour découvrir
              des haïkus d'un thème précis comme l'amour ?
              <br />
              <br />
              Ça se passe juste en dessous, nous espérons que vous apprécierez
              votre visite.
            </>
          ),
        }}
      />
      <Blog
        title={{
          as: "h3",
          value: (
            <>
              Les recueils des <span className="text-green-600">haïkus</span>
              <br />
              classées par thématiques ou par auteurs
            </>
          ),
        }}
        description={
          <>
            Pour le moment, nous avons deux grandes sections dans les recueils,
            il est possible que cela évolue en fonction de vos besoins (par
            exemple une page de recherche avancées où vous pourriez rechercher
            par auteurs et par thématiques en même temps).
          </>
        }
        articles={[
          {
            category: "Par auteurs",
            url: `/repertoire/auteurs/`,
            title: "Le repertoire par auteurs",
            id: "auteurs",
            description: `Vous pouvez retrouver des haïkus produits par les membres de la communauté`,
          },
        ]}
      />

      <Footer />
    </>
  )
}
